import React, { useState, useEffect, useContext } from 'react'
import commonLib from '../control/commonLib';
import konsole from '../control/Konsole';
import Header from './HeaderImage'
import toast, { Toaster } from 'react-hot-toast'

import { useLocation, useNavigate } from 'react-router-dom';
import { AoPartnerBaseUrl,AoIntakeBaseUrl } from '../network/UrlPath';
import HeaderImage from './HeaderImage';
import { UserContext } from '../../App';

function SelectRoles(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [selectroleId, setselectRoleId] = useState(null);
  const [showErrMessage, setShowErrMessage] = useState(false);
  const selectedRolesData = state?.selectedRolesData;
  const selectedRoles = selectedRolesData?.selectedRoles?.some(item => [9, 1].includes(item?.roleId)) ? selectedRolesData?.selectedRoles.filter(item => ![17, 18, 5].includes(item?.roleId)) : selectedRolesData?.selectedRoles; // If the user have role of Academy or Agent with LPO or Intake than filter out the Academy or Agent from select roles list

  // konsole.log("selectedRoles1211",selectedRoles)
  const [showHearAboutUsDropdown, setShowHearAboutUsDropdown] = useState(false);

  const auhtenicateKeys = selectedRolesData?.auhtenicateKeys;

  const [selectedRole, setSelectedRole] = useState('');
  const { setcurMainImageNum } = useContext(UserContext);
  const [selectType, setSelectType] = useState('')
  const filteredRoles = selectedRoles?.filter(role => [3,13,14,15].includes(role.roleId));

  konsole.log("selectedRoles9876",selectedRoles,selectedRolesData, filteredRoles)

  const handleRoleSelect = (roleId, roleKey,type) => {
    if(type !== "staffLogin"){
      setSelectedRole(roleKey);
      setselectRoleId(roleId);
        }  
    setShowHearAboutUsDropdown(false);
    setShowErrMessage("");
    handleClick(roleId,type)
  };

  useEffect(()=>{
    setcurMainImageNum(3)
  },[])

  // useEffect(() => {
  //   let timeout;

  //   if (showErrMessage) {
  //     toast.error("Please select a role.");
  //     timeout = setTimeout(() => {
  //       setShowErrMessage(false);
  //     }, 4000);
  //   }

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [showErrMessage]);


  // const handleChange = (event) => {
  //   konsole.log("event", event.target.value)
  //   setselectRoleId(event.target.value);
  // }
  useEffect(() => {
    const cacheBustedStyle = `${process.env.PUBLIC_URL}/styles.css?v=${new Date().getTime()}`;
    const linkElement = document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.href = cacheBustedStyle;
    document.head.appendChild(linkElement);

    return () => {
      document.head.removeChild(linkElement); // Clean up on unmount
    };
  }, []); // Runs only once on mount


  const handleClick = (roleid,type) => {
    const roleId = roleid ? roleid : selectroleId;
    const appState = auhtenicateKeys.appState;
    const userId = auhtenicateKeys.userId;
    const loggenInId = auhtenicateKeys.loggenInId;
    if (roleId !== '' && roleId !== null && roleId !== undefined) {
      // setShowErrMessage(false);
      if(filteredRoles.length > 0 && type == "staffLogin"){
        setSelectType(type)
        let userPswd=localStorage.getItem('|\/*P*/\|')
        let tokenKey = `appState=${appState}&userId=${userId}&roleId=${JSON.stringify(filteredRoles[0]?.roleId)}&loggenInId=${loggenInId}&Psw=${userPswd}&legalStaff=${true}`;
        window.location.replace(`${AoIntakeBaseUrl}login?token=${window.btoa(tokenKey)}`);
      }else{
        if (roleId == 20) {
          let userPswd = localStorage.getItem('|\/*P*/\|')
          let tokenKey = `appState=${appState}&userId=${userId}&roleId=${JSON.stringify(selectedRoles)}&loggenInId=${loggenInId}&Psw=${userPswd}`;
          window.location.replace(`${AoPartnerBaseUrl}login?token=${window.btoa(tokenKey)}`, "_blank");
        }
        else {
          let finalRoleId = (roleId == 17 || roleId == 18 || roleId == 5) ? JSON.stringify(selectedRoles?.filter(item => item?.roleId == roleId)) : roleId;
          let tokenKey = `appState=${appState}&userId=${userId}&roleId=${finalRoleId}&loggenInId=${loggenInId}`;
          commonLib.redirectToModule(roleId, tokenKey);
        }
      }
    } else {
      // toast.error("Please select a role");
      setShowErrMessage("Please select a role");
    }
  }


  konsole.log(auhtenicateKeys,"selectedRolesselectedRoles", selectedRoles)
  const cancelmodaltosignin = () => {
    navigate('/Account/SignIn')
  }

  return (
    <div id='selectRole' className='w-100'>
      <div className='headerImg' style={{margin :"0 auto"}}>
        <HeaderImage />
      </div>
    <div id='selectRole'>
   
      <div className="vertically-center9 mt-3">
     
      <p className='title text-center'>Accounts switcher</p>
      <label className="w-100 text-center">Here are the accounts you can switch between </label>
      {selectedRoles.length > 0 && (
        <div className="label-input">
          <p className='heading2'>Select account</p>
          {/* <div
            className="row selectBorderHearAboutUs justify-content-between m-0"
            onClick={() => setShowHearAboutUsDropdown(!showHearAboutUsDropdown)}
          >
            <div className="col-10 p-0 hearAboutUsLeftCol">
              <span className={selectedRole ? 'selectedRole' : ''}>{selectedRole || 'Select role'}</span>
            </div>
            <div className="col-1 d-flex justify-content-center hearAboutUsRightCol">
              <img src="/icons/hearAboutOption.svg" alt="dropdown icon" />
            </div>
          </div> */}
          {/* <p className='attention'>{showErrMessage}</p> */}

          {/* {showHearAboutUsDropdown && ( */}
          <div className="roleOptions active-tab w-100">
            
            <div className='d-flex justify-content-between'>
            <div className='ActiveImg'>
            <img width="45px" src="/icons/PersonalInfo-active.svg"/>
            <div className="greenBtn"></div>
            </div>
            <div className='ms-2'>
            <span className="fw-bold" style={{color:"#667085"}}>{auhtenicateKeys.memberName}</span><br/>
            <span style={{color:"#475467"}}>{auhtenicateKeys.email}</span>
            </div>
            </div>
            {/* <img src="/icons/redCheck.svg"/> */}
            </div>
            <div className="d-flex mt-4 w-100">
                <div className="w-100">

                 {/* Sort in desired order */}

                 {selectedRoles.sort((a, b) => {
                  const sortOrder = [3,13,14,15,4,11,6,16];
                  // "3", "13", "14", "15
                  const indexA = sortOrder.indexOf(Number(a.roleId));
                  const indexB = sortOrder.indexOf(Number(b.roleId));

                 // If roleId is not in the sortOrder array, return 0 to retain original position

                 if (indexA == -1 && indexB == -1) return 0;
                 if (indexA == -1) return 1;      // Keep a in original position if it is not in the sortOrder
                 if (indexB == -1) return -1;     // Keep b in original position if it is not in the sortOrder
                return indexA - indexB;           // Sort based on the index in the sortOrder array 
                }).map((data, index) => ( 
                  <div  key={index}  className={selectroleId == data.roleId ? "roleOptions roleOptions-active" : "roleOptions"}
                    onClick={() => handleRoleSelect(data.roleId, data.roleKey)}>
                      <span>{data.roleKey}</span>
                      {/* <img src={selectroleId == data.roleId ? "/icons/redCheck.svg" : "/icons/greyCheck.svg" }/> */}
                      <img src={selectroleId == data.roleId ? "/icons/greenCheck.svg" : "/icons/greyCheck.svg" }/>
                    </div>
                  ))}
                  {filteredRoles.length > 0 && 
                  <div   className={selectType ==  "staffLogin" ? "roleOptions roleOptions-active" : "roleOptions"}
                  onClick={() => handleRoleSelect(filteredRoles[0].roleId, filteredRoles[0].roleKey,"staffLogin")}
                  >
                    <span>Sign-In On App</span>
                    <img src={selectType ==  "staffLogin" ? "/icons/greenCheck.svg" : "/icons/greyCheck.svg" }/>
                </div> 
                  }                 
                </div>
              </div>
          {/* )} */}
        </div>
        
      )}
      </div>
      <div className="label-input">
        {/* <button type="button" onClick={handleClick}>Continue</button> */}
      </div>
    </div>
    </div>
  )
}

export default SelectRoles